<template>
  <v-container fluid>
    <v-row>
      <v-col lg="4" sm="4"> </v-col>
      <v-spacer></v-spacer>
      <v-col lg="4" sm="4" style="text-align: center">
        <v-btn-toggle
          borderless
          class="q-tabs shadow-0"
          v-model="toggle_mode"
          mandatory
          @change="togglePromotions"
        >
          <v-btn value="1">Active</v-btn>
          <v-btn value="2">Inactive</v-btn>
          <v-btn value="12">Completed</v-btn>
        </v-btn-toggle>
      </v-col>
      <v-spacer></v-spacer>
      <v-col class="text-lg-right" lg="4" sm="4">
        <v-btn
            color=" darken-1"
            class="white--text blue-color"
            text
            height="44"
            v-if="checkWritePermission($modules.promotions.management.slug)"
            @click="addPromotion"
        >
          <AddIcon/> <span class="ml-1"> Add Promotion</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-for="promo in promotionsList" :key="promo.id" lg="4" md="4" xl="3" sm="12">
        <promotion-widget v-bind="promo" @delete="deletePromotionWarning" />
      </v-col>
    </v-row>
    <h3 class="text-center mt-12" v-if="promotionsList.length == 0">
      No
      {{
        toggle_mode == 1
          ? "active"
          : toggle_mode == 12
          ? "completed"
          : "inactive"
      }}
      promotion present
    </h3>
    <v-pagination
      v-if="totalPages"
      v-model="page"
      :length="totalPages"
      class="new-pagination"
    ></v-pagination>
    <confirm-model
      v-bind="confirmModel"
      @confirm="confirmActions"
      @close="confirmModel.id = null"
    ></confirm-model>
  </v-container>
</template>

<script>
import PromotionWidget from "@/components/Promotion/PromotionWidget";
import AddIcon from "@/assets/images/misc/plus-icon.svg";

export default {
  components: {
    AddIcon,
    PromotionWidget,
  },
  mounted() {
    this.loadPromotions();
  },
  data() {
    return {
      promotionsList: [],
      page: 1,
      totalPages: 0,
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
      toggle_mode: "1",
    };
  },
  watch: {
    page() {
      this.loadPromotions();
    },
  },

  methods: {
    loadPromotions() {
       this.showLoader("Loading");
      this.$http
        .get(
          `venues/promotions?page=${this.page}&per_page=12&status_id=${this.toggle_mode}`
        )
        .then((response) => {
          this.hideLoader();
          if (response.status == 200 && response.data.status == true) {
            this.promotionsList = response.data.data;
            this.totalPages = response.data.total_pages;
          }
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        });
    },
    addPromotion() {
      this.$router.push({ name: "AddPromotion" });
    },
    deletePromotionWarning(data) {
      this.confirmModel = {
        id: data.id,
        title: `Do you want to ${data.type} this Promotion?`,
        description: `By clicking <b>Yes</b> you can confirm ${data.type} this promotion.  Do you need to continue your action ?`,
        type: "delete",
      };
    },
    confirmActions(data) {
      if (data.type == "delete") {
        this.changePromotionStatus(data.id);
      }
      this.confirmModel.id = null;
    },
    changePromotionStatus(id) {
      this.$http
        .put("venues/promotions/" + id + "/status")
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.loadPromotions();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    togglePromotions(){
      if(this.page != 1){
        this.page = 1;
      }else{
        this.loadPromotions();
      }
    }
  },
};
</script>

<style scoped></style>
